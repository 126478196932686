import * as React from 'react';
import PropTypes from 'prop-types';
import {coinGround} from '../imgUrls';
import './coin.css'

const Coin = ({startNum}) => {

  return (
    <div className="coin">
        <img src={coinGround} className="coin-image"/>
      <div id="incre_coin" className="coin-counter" >{startNum.toLocaleString('en-US')}</div> 
    </div>
  );
};

Coin.propTypes = {
  startNum: PropTypes.number.isRequired,
};

export default Coin;