import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/global.css';
import './CropPage.css';

import Navbar from '../components/Navbar/Navbar';
import Menubar from '../components/Menubar/Menubar';
import LButton from '../components/LButton/LButton';


import { aud_btn } from '../components/audioManager';
import { imgIsld1 } from '../components/imgUrls';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const CropPage = () => {
    const {userId} = useParams();
    const [user, setUser] = useState(null);
    const [boost, setBoost] = useState(0);
    const [volume, setVolume] = useState(50);
    const handleWallet = () => {
        console.log('Wallet clicked');
        aud_btn.play();
    };
    useEffect(() => {
        if (userId) {
          const fetchUser = async () => {
            try {
              const response = await axios.get(`${apiUrl}/api/user/${userId}`);
              setUser(response.data, 100);
            } catch (err) {
              const errorMessage = err?.response?.status === 404
                ? 'User not found'
                : 'Please wait... Coming Soon....';
            }
          };
          fetchUser();
        }
    }, [userId, apiUrl]);

    const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
        aud_btn.play();
         try {
             const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
                 upgrade_obj,
                 upgrade_user,
                 upgrade_type,
             });
             setUser(response.data);
         } catch (error) {
             console.error('Error calling the API:', error);
         }
     };
    if (!user) {
        return <div>Loading...</div>;
    }
    return (
        <>
        <Menubar user={user} boost={boost} func={func} userId={userId} page="crop" vol={volume} setVol={setVolume}/>

        <div className="image-container"> 
            <div>
                <img src={imgIsld1} alt="Island" className="island layer1" />
            </div>
            <LButton 
                top={'50vh'} 
                left={15} 
                onClick={() => handleWallet()}
            />
        </div>
        <Navbar userId={userId}/>
        </>
    );
};
export default CropPage;