import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './FriendPage.css';
import '../styles/global.css';

import { groundImg, friendImg } from '../components/imgUrls';
import { aud_btn } from '../components/audioManager';

import FButton from '../components/FButton/FButton';
import ImageFloatAnimation from '../components/ImageFloatAnimation/ImageFloatAnimation';
import Navbar from '../components/Navbar/Navbar';
import Menubar from '../components/Menubar/Menubar';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const FriendPage = () => {
  const { userId } = useParams();
  const [invitee, setInvitee] = useState(0);
  const [boost, setBoost] = useState(0);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [volume, setVolume] = useState(50);

  useEffect(() => {
      if (userId) {
        const fetchUser = async () => {
          try {
            const response = await axios.get(`${apiUrl}/api/user/${userId}`);
            setUser(response.data, 100);
          } catch (err) {
            const errorMessage = err?.response?.status === 404
              ? 'User not found'
              : 'Please wait... Coming Soon....';
            setError(errorMessage);  // Set the error message
          }
        };
        fetchUser();
      }
  }, [userId]);

  const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
    aud_btn.play();
      try {
          const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
              upgrade_obj,
              upgrade_user,
              upgrade_type,
          });
          setUser(response.data);
      } catch (error) {
          console.error('Error calling the API:', error);
      }
  };
  const fetchData = async (url, setter, errorMessage) => {
      try {
          const response = await axios.get(url);
          setter(response.data);
      } catch (err) {
          setError(err.response?.status === 404 ? `${errorMessage} not found` : `Error fetching ${errorMessage.toLowerCase()}`);
      }
  };
  useEffect(() => {
      if (user) {
          fetchData(`${apiUrl}/api/boost/${user.boost_id}`, setBoost, 'Boost');
        }
  }, [user]);
  const getSharedUrl = async (userId) => {
    try {
      const response = await axios.get(`${apiUrl}/api/user/referral/link/${userId}`);
      return response.data;
    } catch (err) {
      console.error('Error fetching referral URL:', err);
      throw new Error('Failed to get referral URL');
    }
  };

  const handleFriend = async () => {
    try {
      const shareUrl = await getSharedUrl(userId);
      console.log('Referral URL:', shareUrl);
      window.location.href = shareUrl;
    } catch (err) {
      console.error('Error handling friend invite:', err);
    }
  };

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted component
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/user/${userId}`);
        if (isMounted) setUser(response.data);
      } catch (err) {
        if (isMounted) {
          setError(err.response?.status === 404 ? 'User not found' : 'Error fetching user');
        }
      }
    };
    fetchUser();

    return () => {
      isMounted = false; // Cleanup for component unmount
    };
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const fetchInvitee = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/referral/${userId}`);
          setInvitee(response.data);
        } catch (err) {
          const errorMessage = err?.response?.status === 404
            ? 'Error getting Invitee Count'
            : 'Please wait... Coming Soon....';
          setError(errorMessage);  // Set the error message
        }
      };
      fetchInvitee();
    }
}, [userId, apiUrl]);

  // Render error message
  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // Render loading state
  if (!user) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <>
        <Menubar user={user} boost={boost} func={func} userId={userId} page="friend" vol={volume} setVol={setVolume}/>
        <div > 
            <div className="image-container">
                <img src={friendImg} alt="Island" className="friend layer1" />
            </div>

            <div className="image-ground">
                <img src={groundImg} alt="Island" className="ground-img"/>
            </div>
            <div className="image-text">
                Invite Friends
            </div>
        </div>
        
    <ImageFloatAnimation settings={{ count: 30, speed: 3     }} />
        <FButton 
            top={'68vw'} 
            left={15} 
            lavel={'Invite a friend.'}
            onClick={() => handleFriend()}
        />
        <div className="invite-friend">
        You invited <span className = "invitee" >{invitee}</span> friends.
            </div>
        <Navbar userId={userId}/>
    </>
);
};
export default FriendPage;
