import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import './ShopPage.css';
import '../styles/global.css';

import {img1, img2, img3, img4, img5, img6, shopGround} from '../components/imgUrls';
import { aud_btn } from '../components/audioManager';

import Navbar  from '../components/Navbar/Navbar';
import Menubar from '../components/Menubar/Menubar';
import BonusCard from '../components/Shop/BonusCard';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const ShopPage = () => {
    const {userId} = useParams();
    const [volume, setVolume] = useState(50);
    const [user, setUser] = useState(null);
    const [island, setIsland] = useState(null);
    const [streamer, setStreamer] = useState(null);
    const [plant, setPlant] = useState(null);
    const [error, setError] = useState('');
    const [boost, setBoost] = useState(0);

    const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
        aud_btn.play();
         try {
             const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
                 upgrade_obj,
                 upgrade_user,
                 upgrade_type,
             });
             setUser(response.data);
         } catch (error) {
             console.error('Error calling the API:', error);
         }
     };

    const fetchData = async (url, setter, errorMessage) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
            console.log(`${errorMessage} data:`, response.data);
        } catch (err) {
            setError(err.response?.status === 404 ? `${errorMessage} not found` : `Error fetching ${errorMessage.toLowerCase()}`);
        }
    };
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/user/${userId}`);
                setUser(response.data);
            } catch (err) {
                setError(err.response?.status === 404 ? 'User not found' : 'Error fetching user');
            }
        };

        fetchUser();
    }, [userId]);

    useEffect(() => {
        if (user) {
            fetchData(`${apiUrl}/api/island/${user.island_level}`, setIsland, 'Island');
            fetchData(`${apiUrl}/api/streamer/${user.streamer_level}`, setStreamer, 'Streamer');
            fetchData(`${apiUrl}/api/plant/${user.plant_level}`, setPlant, 'Plant');
        }
    }, [user]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!user || !island || !streamer || !plant) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Menubar user={user} boost={boost} func={func} userId={userId} page="shop" vol={volume} setVol={setVolume}/>
            <div > 
                <div className="image-container">
                    <img src={shopGround} alt="Island" className="friend layer1" />
                </div>
            <div className="card">
            <Grid container spacing={0}>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img1} text={'Join the community'} url={'./community'} plus={100000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img2} text={'Join News Channel'} url={'../'} plus={100000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img3} text={'YouTube subscription'} url={'../'} plus={120000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img4} text={'Latest News Post'} url={'../'} plus={50000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img5} text={'Retweeting on X'} url={'../'} plus={50000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img6} text={'watching youtube short'} url={'../'} plus={50000}/>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }}>
                < BonusCard img={img6} text={'watching youtube short'} url={'../'} plus={50000}/>
                </Grid>
            </Grid>
                </div>
            </div>
            <Navbar userId={userId}/>
        </>
    );
};

export default ShopPage;