//imgUrl.js

// Define and export image URLs
export const imgIsld1 = 'https://i.ibb.co/xjMhNJV/1.jpg';
export const imgIsld2 = 'https://i.ibb.co/n71JKng/2.jpg';
export const imgIsld3 = 'https://i.ibb.co/bKCBvJF/3.jpg';
export const imgIsld4 = 'https://i.ibb.co/74c0K2n/4.jpg';
export const imgIsld5 = 'https://i.ibb.co/DRF9RMj/5.jpg';
export const imgIsld6 = 'https://i.ibb.co/sWgW1Hb/6.jpg';
export const imgIsld7 = 'https://i.ibb.co/Qnb8XMJ/7.jpg';
export const imgIsld8 = 'https://i.ibb.co/68Dkk93/8.jpg';
export const imgIsld9 = 'https://i.ibb.co/WDsF2pc/9.jpg';
export const imgIsld10 = 'https://i.ibb.co/fnW3YQ9/10.jpg';
export const imgIsld11 = 'https://i.ibb.co/523Mqgw/11.jpg';
export const imgIsld12 = 'https://i.ibb.co/1fccMMv/12.jpg';

export const imgStr1 = 'https://i.ibb.co/y4cPdJZ/1.png';
export const imgStr2 = 'https://i.ibb.co/2szbQDz/2.png';
export const imgStr3 = 'https://i.ibb.co/ZcKykV3/3.png';
export const imgStr4 = 'https://i.ibb.co/CJrVbZP/4.png';
export const imgStr5 = 'https://i.ibb.co/vBrb5XS/5.png';
export const imgStr6 = 'https://i.ibb.co/7SZF05k/6.png';
export const imgStr7 = 'https://i.ibb.co/gVhwJMx/7.png';
export const imgStr8 = 'https://i.ibb.co/tDMgT9V/8.png';
export const imgStr9 = 'https://i.ibb.co/qmpfsKH/9.png';
export const imgStr10 = 'https://i.ibb.co/Ctg94Cc/10.png';

export const imgPlt1 = 'https://i.ibb.co/f0N6cYX/1.png';
export const imgPlt2 = 'https://i.ibb.co/M9XK0Tc/2.png';
export const imgPlt3 = 'https://i.ibb.co/64SkCzQ/3.png';
export const imgPlt4 = 'https://i.ibb.co/ZgsXfQb/4.png';
export const imgPlt5 = 'https://i.ibb.co/1K0P8BJ/5.png';
export const imgPlt6 = 'https://i.ibb.co/XWJdsdW/6.png';
export const imgPlt7 = 'https://i.ibb.co/DfkCGxq/7.jpg';
export const imgPlt8 = 'https://i.ibb.co/qBr9KSB/8.png';
export const imgPlt9 = 'https://i.ibb.co/kxqrR3J/9.png';

export const fruitImg1 = 'https://i.ibb.co/MPHhJn6/energy.png';     //Energy
export const fruitImg2 = 'https://i.ibb.co/R2FFyHn/coin.png';    //Coin
export const fruitImg3 = 'https://i.ibb.co/Hn58Sch/visitor.png';   //Visitor
export const fruitImg4 = 'https://i.ibb.co/r6760cP/bear.png';     //Bear
export const fruitImg5 = 'https://i.ibb.co/pyZGPyx/bull.png';     //Bull
export const fruitImg6 = 'https://i.ibb.co/rpMWqCW/minion.png';     //Doll

export const buildIcon = 'https://i.ibb.co/y6htSRm/build.png';
export const  cropIcon = 'https://i.ibb.co/XVM61d7/crop.png';
export const  friendIcon = 'https://i.ibb.co/cyzftc1/friend.png';
export const  shopIcon = 'https://i.ibb.co/6bp0yYy/shop.png';
export const  spinIcon = 'https://i.ibb.co/HXMXpB1/spin.png';

export const iconImg2 = 'https://i.ibb.co/MPHhJn6/energy.png';
export const iconImg1 = 'https://i.ibb.co/R2FFyHn/coin.png';

export const shopimg = 'https://i.ibb.co/2h6mS25/market.png';
export const shopImg = 'https://i.ibb.co/5L7CTjt/shop-inside.png';
export const groundImg = 'https://i.ibb.co/g7rSs6M/friend-board.png';
export const friendImg = 'https://i.ibb.co/g9yKsyD/friend-back.jpg';
export const slotImg = "https://i.ibb.co/Wpkqmr9/new-slot.png";
export const boost_backgroundImg = 'https://i.ibb.co/Bj8xPhB/border.png';
export const boost1 = 'https://i.ibb.co/zHjTGNv/boost1.png';
export const boostimg = 'https://i.ibb.co/MfSjJ5F/boost.png';
export const boostMissle = 'https://i.ibb.co/NSKz5K3/boost-Missile.png';
export const coinGround = "https://i.ibb.co/7RGDw4Z/coin-ground.png";
export const visitorGround = "https://i.ibb.co/wgS4PM1/visitor-ground.png";
export const shopGround = "https://i.ibb.co/F8wHZfC/shop-back.jpg";
// https://i.ibb.co/71tbdng/evergy-pair.png

// https://i.ibb.co/F54SfSC/shop-ground.png";
// 
// 

export const volumeimg = 'https://i.ibb.co/Ssg3VLf/sound2.png';
export const avatarImg = '../../src/assets/images/icons/avat.webp';
export const iconImg = 'https://i.ibb.co/6JpBWGd/float.png'

export const img1 = 'https://i.ibb.co/5kzBhGf/animalsandcoins.png';
export const img2 = 'https://i.ibb.co/g4PgHLz/discord.png';
export const img3 = 'https://i.ibb.co/LkMJ5vF/youtube.png';
export const img4 = 'https://i.ibb.co/sb5xZZK/telegram.png';
export const img5 = 'https://i.ibb.co/1QJXWDX/twitter.png';
export const img6 = 'https://i.ibb.co/g4PgHLz/discord.png';







// https://i.ibb.co/HdVmtzY/board.jpg

// https://i.ibb.co/YkjL93c/loading.png
// https://i.ibb.co/mJRtg2X/ton.jpg

// You can also create image arrays
export const islandImages = [
  imgIsld1, imgIsld2, imgIsld3, imgIsld4, imgIsld5,
  imgIsld6, imgIsld7, imgIsld8, imgIsld9, imgIsld10,
  imgIsld11, imgIsld12,
];

export const streamerImages = [
  imgStr1, imgStr2, imgStr3, imgStr4, imgStr5,
  imgStr6, imgStr7, imgStr8, imgStr9, imgStr10,
];

export const plantImages = [
  imgPlt1, imgPlt2, imgPlt3, imgPlt4, imgPlt5,
  imgPlt6, imgPlt7, imgPlt8, imgPlt9,
];

export const fruitImages = [
  fruitImg1, fruitImg2, fruitImg3, 
  fruitImg4, fruitImg5, fruitImg6,
]