import * as React from 'react';
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './SlotPage.css';
import '../styles/global.css';

import { islandImages, fruitImages, slotImg } from '../components/imgUrls';
import { aud_btn, audio2, aud_slot} from '../components/audioManager';

import SButton from '../components/SButton/SButton';
import Menubar from '../components/Menubar/Menubar';
import Navbar  from '../components/Navbar/Navbar';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const elementCounts1 = [3, 2, 2, 1, 1, 1];
const elementCounts2 = [1, 1, 1, 2, 1, 1];
const elementCounts3 = [1, 2, 2, 1, 1, 2];


const playSoundRepeatedly = (audio, time) => {
  let playCount = 0;
  
  const interval = setInterval(() => {
    if(time>1){
        if (playCount < Math.floor(time)) {
            audio.play();  // Play the sound
          playCount++;
        } else {
          clearInterval(interval);  // Stop the interval after 5 plays
        }
    }
    else {
        if (playCount < Math.floor(time*5)) {
            audio.play();  // Play the sound
          playCount++;
        } else {
          clearInterval(interval);  
        }
    }
  }, 200); 
};


function createRandomizedArray(oldArray, elementCounts) {
    const tempArray = [];
    oldArray.forEach((item, index) => {
        const count = elementCounts[index] || 0;
        for (let i = 0; i < count; i++) {
            tempArray.push(item);
        }
    });
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    let shuffledArray = shuffleArray(tempArray);
    return shuffledArray;
}

function createDivElements(mainArray, referenceArray, className) {
    return mainArray.map((divelement, index) => {
        const refIndex = referenceArray.indexOf(divelement);
        if (refIndex === -1) {
            throw new Error(`Element "${divelement}" not found in reference array.`);
        }
        return (
            <>
                <div className={className} key={refIndex}>
                    <img src={divelement} className="fruit_1" />
                </div>
            </>
        );
    });
}

const SlotPage = () => { 
    const { userId } = useParams();
    const [boost, setBoost] = useState(0);
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [volume, setVolume] = useState(50);
    const [spin, setSpin] = useState(false)
    const [ring1, setRing1] = useState(false)
    const [spinValid, setSpinValid] = useState(true);
    const [ring2, setRing2] = useState(false)
    const [ring3, setRing3] = useState(false)
    const array1 = createRandomizedArray(fruitImages, elementCounts1);
    const array2 = createRandomizedArray(fruitImages, elementCounts2);
    const array3 = createRandomizedArray(fruitImages, elementCounts3);
    const [divArray1, setDivArray1] = useState(createDivElements(array1, fruitImages, "ringEnd"));
    const [divArray2, setDivArray2] = useState(createDivElements(array2, fruitImages, "ringEnd"));
    const [divArray3, setDivArray3] = useState(createDivElements(array3, fruitImages, "ringEnd"));
    useEffect(() => {
        const updateStyles = () => {
          const slotImg = document.getElementById("slotImg");
          const slot = document.getElementById("slot");
          const windowHeight = window.innerHeight;
          const windowWidth = window.innerWidth;
    
          // Check if the width of #slotImg is 33% of the window width
          if (slotImg && slot && slotImg.offsetWidth > windowHeight * 0.32 ) {
            // Set the top of #slot as 20% of the window width
            // slot.style.top = `${windowWidth * 0.2}px`;
          }
        };
        updateStyles();
        window.addEventListener('resize', updateStyles);
        return () => {
          window.removeEventListener('resize', updateStyles);
        };
      }, []);
    
    function valid_buttons(energy){
        if (energy == 0) {
            setSpinValid(true);
        } else {
            setSpinValid(false);
        }
        console.log("Button", spinValid);
    }

    const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
        try {
            const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
                upgrade_obj,
                upgrade_user,
                upgrade_type,
            });
            setUser(response.data);
            valid_buttons(response.data.energy);
            console.log(response.data);
        } catch (error) {
            console.error('Error calling the API:', error);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
          try {
            const response = await axios.get(`${apiUrl}/api/user/${userId}`);
            setUser(response.data);
            valid_buttons(response.data.energy);
          } catch (err) {
            setError(err?.response?.status === 404 ? 'User not found' : 'Please wait... Coming Soon....');
          }
        };
    
        fetchUser();
      }, [userId]);


    if (error) {
        return <div>{error}</div>;
    }
    if (!user) {
        return <div>Loading...</div>;
    }
    function row1() {
        if(spin && ring1){
            return (
                <>
                    {createDivElements(fruitImages, fruitImages, "ringMoving")}
                </>
            )
        } else {
            return (
                <>
                    {divArray1}
                </>
            )
        }
    }
    function row2() {
        if(spin && ring2){
            return (
                <>
                    {createDivElements(fruitImages, fruitImages, "ringMoving")}
                </>
            )
        } else {
            return (
                <>
                    {divArray2}
                </>
            )
        }
    }
    function row3() {
        if(spin && ring3){
            return (
                <>
                    {createDivElements(fruitImages, fruitImages, "ringMoving")}
                </>
            )
        } else {
            return (
                <>
                    {divArray3}
                </>
            )
        }
    }
function price(num1, num2, num3){
    let coins = 0;
    let visitors = 0;
    let energy = 0;
    if(num1 === num2 && num2 === num3){
        switch(num1){
            case 0:
                energy = 3;
                break;
            case 1:
                coins = 1000;
                break;
            case 2:
                visitors = 50;
                break;
            case 3: 
                coins = -3000;
                break;
            case 4:
                coins = 3000;
                break;
            case 5:
                coins = 1000;
                energy = 1;
                visitors = 10;
                break;
            default:
                break;
            }  
    } else if(num1 === num2 || num2 === num3 || num1 === num3){
        let flag = 0;
        if(num1 === num2) flag = num1;
        else if(num2 === num3) flag = num2;
        else flag = num1;
        switch(flag){
            case 0:
                energy = 1;
                break;
            case 1:
                coins = 500;
                break;
            case 2:
                visitors = 10;
                break;
            case 3: 
                coins = -1000;
                break;
            case 4:
                coins = 1000;
                break;
            case 5:
                coins = 100;
                break;
            default:
                break;
            }
    } else {
        coins = 100;
    }
    return [coins, visitors, energy];
}
function PlaySlot() {
    
    playSoundRepeatedly(aud_btn, 1);
    playSoundRepeatedly(aud_slot, 7);
    if(!spin){
        setSpin(true);
        setRing1(true);
        setRing2(true);
        setRing3(true);
        const array1 = createRandomizedArray(fruitImages, elementCounts1);
        const array2 = createRandomizedArray(fruitImages, elementCounts2);
        const array3 = createRandomizedArray(fruitImages, elementCounts3);
        const num1 = fruitImages.indexOf(array1[1]);
        const num2 = fruitImages.indexOf(array2[1]);
        const num3 = fruitImages.indexOf(array3[1]);
        console.log(num1);
        console.log(num2);
        console.log(num3);

        func (-1, user, "energy_loss");

        setDivArray1(createDivElements(array1, fruitImages, "ringEnd"));
        setDivArray2(createDivElements(array2, fruitImages, "ringEnd"));
        setDivArray3(createDivElements(array3, fruitImages, "ringEnd"));
        setTimeout(() => {
            setRing1(false);
            setTimeout(() => {
                setRing2(false);
                setTimeout(() => {
                    setRing3(false);
                    setSpin(false);
                    audio2.play();
                    let value_price = price(num1, num2, num3)
                    console.log(value_price);
                    console.log(userId);
                    func(value_price, user, "slot");
                    let text = "";
                    if(value_price[0]){
                        text += `<div class="display_value2">
                        <img src="${fruitImages[1]}" alt="Energy" class="fruit2" />
                        <div class="value2">
                            × ${value_price[0]}
                        </div>
                    </div>`
                    }
                    if(value_price[1]){
                        text += `<div class="display_value2">
                        <img src="${fruitImages[2]}" alt="Energy" class="fruit2" />
                        <div class="value2">
                            × ${value_price[1]}
                        </div>
                    </div>`
                    }
                    if(value_price[2]){
                        text += `<div class="display_value2">
                        <img src="${fruitImages[0]}" alt="Energy" class="fruit2" />
                        <div class="value2">
                            × ${value_price[2]}
                        </div>
                    </div>`
                    }
                    document.getElementById("display_value").innerHTML = text;
                // Remove or hide the element after 0.5 seconds (500ms)
                setTimeout(() => {
                    document.getElementById("display_value").innerHTML = "";
                }, 1500);
                }, 1000);
            }, 1000);
        }, 1000);
    }
    
}


  return (
    <>
    <Menubar user={user} boost={boost} func={func} userId={userId} page="slot" vol={volume} setVol={setVolume}/>
    <div className="image-container"> 
        <div>
            <img src={islandImages[user.island_level]} alt="kk" className="island layer1" />
        </div>
    <div className="slot_img2">
        <div id="display_value" className="display_value">
        <div className="display_value2">
        </div>
        </div>
        <div id="slot" className="slot">
            <div className="row">
            <div className="kkk">
            </div>
                {row1()}
            </div>
            <div className="row">
            <div className="kkk">
            </div>
                {row2()}
            </div>
            <div className="row">
                <div className="kkk">
                    </div>
                {row3()}
            </div>
        </div>
      <img id="slotImg" src={slotImg} alt="Background" className="slot_img3" />
    </div>
    <div className="btn_area">
        <SButton label="SPIN" onClick={() => PlaySlot()} isdisabled = {spinValid}/>
    </div>
    </div>
    <Navbar userId={userId}/>
</>
  );
};
export default SlotPage;
