import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import './TonShopPage.css';
import '../styles/global.css'; 

import { groundImg, shopImg } from '../components/imgUrls';
import { aud_btn } from '../components/audioManager';

import Menubar from '../components/Menubar/Menubar';
import ProductCard from '../components/Shop/ProductCard';
import Navbar  from '../components/Navbar/Navbar';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const TonShopPage = () => {
    const [volume, setVolume] = useState(50);
    const {userId} = useParams();
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [boost, setBoost] = useState(null);
    useEffect(() => {
        if (userId) {
          const fetchUser = async () => {
            try {
              const response = await axios.get(`${apiUrl}/api/user/${userId}`);
              setUser(response.data, 100);
            } catch (err) {
              const errorMessage = err?.response?.status === 404
                ? 'User not found'
                : 'Please wait... Coming Soon....';
            }
          };
          fetchUser();
        }
    }, [userId, apiUrl]);
    const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
        aud_btn.play();
         try {
             const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
                 upgrade_obj,
                 upgrade_user,
                 upgrade_type,
             });
             setUser(response.data);
         } catch (error) {
             console.error('Error calling the API:', error);
         }
     };

    if (error) {
        return <div>{error}</div>;
    }
    if (!user) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <Menubar user={user} boost={boost} func={func} userId={userId} page="tonshop" vol={volume} setVol={setVolume}/>
            <div > 
                <div className="image-container">
                    <img src={shopImg} alt="Island" className="friend layer1" />
                </div>
                <div className="image_ground_shop">
                    <img src={groundImg} alt="Island" className="ground-img-shop"/>
                </div>
            <div className="card">
            <Grid container spacing={2}>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={60} text={'50% more'} cost={3.29}/>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={124} text={'80% more'} cost={7.89}/>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={300} text={'100% more'} cost={11.99}/>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={700} text={'120% more'} cost={129.99}/>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={'1.4K'} text={'180% more'} cost={332.98}/>
                </Grid>
                <Grid size={{ xs: 6, md: 6 }}>
                < ProductCard energy={'2.1K'} text={'300% more'} cost={999.99}/>
                </Grid>
            </Grid>
                </div>
            </div>
            <Navbar userId={userId}/>
        </>
    );
};

export default TonShopPage;