import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import '../UButton/UButton.css';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '33vw',
  borderRadius: '20%',
  minWidth: '100px',
  maxWidth: '300px',
  fontFamily: 'Sheriff Bounce',
  backgroundColor: '#8ce06b',
  color: 'White',
  textShadow: '1px 1px 0 #000',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#498233',
  },
}));

const BoostClickButton = ({ cost = 30, onClick, isValid }) => {
  return (
    <div>
      <StyledButton 
        variant="contained" 
        onClick={onClick} 
        style={{ padding: '0px'}} 
        disabled={!isValid} // Disable button if isValid is false
      >
        <span className="num6" style={{ marginRight: '8vw' }}>
          {cost === 0 ? 'FREE' : `$${cost}`}
        </span>
      </StyledButton>
    </div>
  );
};

export default BoostClickButton;