// audioManager.js
import sound1 from '../assets/sound/kkk.mp3';
import sound2 from '../assets/sound/kkk.mp3';
import sound3 from '../assets/sound/kkk.mp3';
import sound4 from '../assets/sound/kkk.mp3';
import sound5 from '../assets/sound/kkk.mp3';
import sound6 from '../assets/sound/kkk.mp3';
import sound7 from '../assets/sound/slot_moving_card.mp3';
// Create the audio object
const aud_btn = new Audio(sound1);
const aud_slot = new Audio(sound7);
const audio2 = new Audio(sound2);
const audio3 = new Audio(sound3);
const audio4 = new Audio(sound4);
const audio5 = new Audio(sound5);
const audio6 = new Audio(sound6);

// You can also export other audio objects in the same way if needed
// const audio2 = new Audio('../assets/sound/otherSound.mp3');

// Export the audio object for use in other files
export { aud_btn, audio2, audio3, audio4, audio5, audio6, aud_slot };