import React from 'react';
import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';
import Visitor from '../Visitor/Visitor';
import BoostIcon from '../Boost/BoostIcon';
import VolumeControlDialog from '../VolumeControlDialog/VolumeControlDialog';
import AlertDialog from '../AlertDialog/AlertDialog';
import ImageToggleButton from '../ImageToggleButton/ImageToggleButton';
import Coin from '../Coin/Coin';
import './Menubar.css';
import Energybar from '../Navbar/Energybar';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Menubar = ({user, boost, func, userId, page, vol, setVol}) => {
  function boostTime() {
    const currentTimeInGMT = DateTime.now().setZone('GMT').toString();
    const differenceInSeconds = Math.floor((new Date(currentTimeInGMT) - new Date(user.boost_started_at)) / 1000) ;
    return differenceInSeconds;
  }
  if(page == "friend" || page == "shop"){
    return (
      <div className="menu-container">
        <div className="menu-item menu-coin">
          <Coin startNum={user.coins} />
        </div>
        <div className="menu-item menu-visitor">
          <Visitor startNum={user.visitors} increVal={user.hourly_visitor * (user.boost_id < 5 ? 2: 1)} />
        </div>
        <div className="menu-item menu-volume">
          <VolumeControlDialog vol={vol} handleVolume={setVol} username = {user.fullname} img = {user.avatar_url} />
        </div>
        <AlertDialog text_origin="KKK" open={false} />
      </div>
    );
  }else {
    return (
      <div className="menu-container">
        <div className="menu-item menu-coin">
          <Coin startNum={user.coins} />
        </div>
        <div className="menu-item menu-visitor">
          <Visitor startNum={user.visitors} increVal={user.hourly_visitor * (user.boost_id < 5 ? 2: 1)} />
        </div>
        <div className="menu-item menu-boost">
          <BoostIcon now = {boostTime()} total = {boost.time * 60} user = {user} func = {func} />
        </div>
        <div className="menu-item menu-volume">
          <VolumeControlDialog vol={vol} handleVolume={setVol} username = {user.fullname} img = {user.avatar_url} />
        </div>
        <AlertDialog text_origin="KKK" open={false} />
        {/* <div className="menu-item menu-shop">
          <Link to={`/${userId}/tonshop`}>
            <ImageToggleButton />
          </Link>
        </div> */}
        <div className="menu-item menu-energy" style={{ position: 'absolute', zIndex: '10' }}>
          <Energybar total={user.max_energy} now={user.energy} />
        </div>
      </div>
    );
  }
  
};

export default Menubar;
