import * as React from 'react';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuIcon from './MenuIcon'

import './Navbar.css';

import { toRoman } from '../utils';
import { buildIcon, cropIcon, spinIcon, friendIcon, shopIcon } from '../imgUrls';
import { aud_btn } from '../audioManager';

export default function Navbar({ userId }) {
  const buttons = [
    <MenuIcon imageSrc={buildIcon} text="BUILD" iconClicked={false} destination={`/${userId}/build`} />,
    <MenuIcon imageSrc={cropIcon} text="CROP" iconClicked={false} destination={`/${userId}/crop`} />,
    <MenuIcon imageSrc={spinIcon} text="SPIN" iconClicked={false} destination={`/${userId}/spin`} />,
    <MenuIcon imageSrc={friendIcon} text="FRIEND" iconClicked={false} destination={`/${userId}/friend`} />,
    <MenuIcon imageSrc={shopIcon} text="SHOP" iconClicked={false} destination={`/${userId}/shop`} />
  ];
  return (
    <div className= "bottom-menu" >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <ButtonGroup size="large" aria-label="Large button group" className="bottom-height">
          {buttons}
        </ButtonGroup>
      </Box>
    </div>
  );
}