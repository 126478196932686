import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Button from '../BoostClickButton/BoostClickButton';

export default function BoostCard({id = 1, cost=100, func, user, isValid}) {
  function idToTime(id) {
    switch(id){
      case 1:
        return 10;
      case 2:
        return 30;
      case 3:
        return 60;
      case 4:
        return 120;
      default:
        return 0; 
    }
  }
  const handleBuyClick = async (boost_id) => {
    console.log("BoostCard handleBuyClick");
    console.log("func(boost_id, user, 'boost');");
     func(boost_id, user, 'boost');
  }
  return (
    <Card sx={{ 
      overflow: 'hidden',
      width: '30vw',
      border: '2px solid #e9824d',
      boxShadow: '2px 1px 4px #612727',
      borderRadius: '20%',
      backgroundColor: '#ffeddd',
      margin: '0vw',}}>
      <CardContent style={{padding:0}}>
        <Typography variant="h5" component="div" style={{
          fontFamily: 'Sheriff Bounce',
          fontSize: '6vw',
          margin: 0,
          fontColor: 'cyan',
          color: '#51e9e5',
          textShadow: '2px 2px 1px #0f7675',
          justifyContent: 'center',
        }}>
          {idToTime(id)}min
        </Typography>
      </CardContent>
      <CardActions style={{padding: '0px',
          margin: 'auto',
          justifyContent: 'center',
          display: 'flex',
          marginBottom: '2px',
          }}>
        <Button 
        cost={cost} 
        onClick={() => handleBuyClick(id)}
        isValid = {isValid}></Button>
      </CardActions>
    </Card>
  );
}
