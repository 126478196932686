import React, { useEffect, useState } from 'react';
import { useParams, Link, useSearchParams  } from 'react-router-dom';
import axios from 'axios';
import './BuildPage.css';

import { toRoman } from '../components/utils';
import { islandImages, streamerImages, plantImages } from '../components/imgUrls';
import { aud_btn } from '../components/audioManager';

import Navbar from '../components/Navbar/Navbar';
import Menubar from '../components/Menubar/Menubar';
import Building from '../components/Building/Building';
import UButton from '../components/UButton/UButton';
import HButton from '../components/HButton/HButton';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BuildPage = () => {
    const {userId} = useParams();
    const [searchParams] = useSearchParams();
    const [boost, setBoost] = useState(0);
    const [user, setUser] = useState(null);
    const [island, setIsland] = useState(null);
    const [streamer, setStreamer] = useState(null);
    const [plant, setPlant] = useState(null);
    const [error, setError] = useState('');
    const [islandValid, setIslandValid] = useState(false);
    const [streamerValid, setStreamerValid] = useState(false);
    const [plantValid, setPlantValid] = useState(false);
    const [volume, setVolume] = useState(30);
    const [count, setCount] = useState(0);
    let referralCode = searchParams.get('referralCode');

    function energy_time(updated_at){
        const now = new Date();
        const updatedTime = new Date(updated_at);
        const differenceInSeconds = Math.floor((now - updatedTime) / 1000);
        return differenceInSeconds;
    }

    function valid_buttons(user){
        if (user.island_level * 3 >= user.streamer_level || user.island_level *2 >= user.plant_level) {
            setIslandValid(true);
            } else {
            setIslandValid(false);
            }
        if (user.streamer_level >= 30 || streamer?.upgrade_energy > user.energy || streamer?.upgrade_coin > user.coins)
            setStreamerValid(true);
        else 
            setStreamerValid(false);
        if (user.plant_level >= 30 || plant?.upgrade_energy > user.energy || plant?.upgrade_coin > user.coins)
            setPlantValid(true);
        if (user.island_level >= 11 || island?.upgrade_energy > user.energy || island?.upgrade_coin > user.coins)
            setIslandValid(true);
    }
    useEffect(() => {
        if (userId) {
          const fetchUser = async () => {
            try {
              const response = await axios.get(`${apiUrl}/api/user/${userId}`);
              setUser(response.data, 100);
              valid_buttons(response.data);
            } catch (err) {
              const errorMessage = err?.response?.status === 404
                ? 'User not found'
                : 'Please wait... Coming Soon....';
              setError(errorMessage);  // Set the error message
            }
          };
          fetchUser();
        }
    }, [userId, apiUrl]);

    const func = async (upgrade_obj, upgrade_user, upgrade_type) => {
       aud_btn.play();
        try {
            const response = await axios.post(`${apiUrl}/api/user/upgrade`, {
                upgrade_obj,
                upgrade_user,
                upgrade_type,
            });
            setUser(response.data);
            valid_buttons(response.data);
            // setCounter(energy_time(user.energy_updated_at));
        } catch (error) {
            console.error('Error calling the API:', error);
        }
    };
    const fetchData = async (url, setter, errorMessage) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
        } catch (err) {
            setError(err.response?.status === 404 ? `${errorMessage} not found` : `Error fetching ${errorMessage.toLowerCase()}`);
        }
    };
    useEffect(() => {
        if (user) {
            fetchData(`${apiUrl}/api/island/${user.island_level}`, setIsland, 'Island');
            fetchData(`${apiUrl}/api/streamer/${user.streamer_level}`, setStreamer, 'Streamer');
            fetchData(`${apiUrl}/api/plant/${user.plant_level}`, setPlant, 'Plant');
            fetchData(`${apiUrl}/api/boost/${user.boost_id}`, setBoost, 'Boost');
          }
    }, [user]);
    useEffect(() => {
        if (userId && referralCode) {
            sendReferralCode(referralCode);
            referralCode = null;
        }
      }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
          setCount((prevCount) => prevCount + 1);
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);
    const sendReferralCode = async (referralCode) => {
        const url = `${apiUrl}/api/referral/redeem`;
        const data = { referralCode: referralCode };  // Send referralCode in the request body
        const text_inviterId = referralCode.match(/\d+/g);
        const inviterId = text_inviterId.join('');
        try {
            const response = await axios.post(url, {
              inviter: inviterId,
              invitee: userId,
              referralCode: referralCode,
            }, {
              headers: {
                'Content-Type': 'application/json',
              }
            });
          } catch (error) {
            console.error('Error:', error);
          }          
    }
    if (error) {
        return <div>{error}</div>;
    }
    if (!user || !island || !streamer || !plant || !boost) {
        return <div>Loading...</div>;
    }
    return (
        <>
        <Menubar user={user} boost={boost} func={func} userId={userId} page="build" vol={volume} setVol={setVolume}/>
        <div className="image-container"> 
          <div>
              <img src={islandImages[user.island_level]} alt="Island" className="island layer1" />
          </div>
          <div className="image layer2">
              <Building 
                  imageSrc={streamerImages[user.streamer_level%9]} 
                  level={user.streamer_level} 
                  upgradeCoin={island.upgrade_coin} 
                  upgradeEnergy={island.upgrade_energy} 
              />
              <HButton 
                  energy={streamer.upgrade_energy} 
                  coin={streamer.upgrade_coin} 
                  top={'25vh'} 
                  left={5} 
                  onClick={() => func(streamer, user, 'streamer')}
                  isdisabled = {streamerValid }
                  />
                  <div className="level_show">
                      {user.streamer_level}
                  </div>
          </div>
          <div className="image layer3">
              <Building 
                  imageSrc={plantImages[user.plant_level%9]} 
                  level={user.plant_level} 
                  upgradeCoin={30000} 
                  upgradeEnergy={80} 
              />
              <HButton
                  energy={plant.upgrade_energy} 
                  coin={plant.upgrade_coin} 
                  top={'25vh'} 
                  left={5} 
                  onClick={() => func(plant, user, 'plant')}
                  isdisabled = {plantValid}
                  />
                  <div className="level_show">
                      {user.plant_level}
                  </div>
          </div>
          <UButton 
              energy={island.upgrade_energy} 
              coin={island.upgrade_coin} 
              top={'67vh'} 
              left={25} 
              onClick={() => func(island, user, 'island')}
              isdisabled = {islandValid}
          />
          <div className="level_show_2">{toRoman(user.island_level)}</div>
        </div>
        <Navbar userId={userId}/>
        </>
    );
};

export default BuildPage;