import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams} from 'react-router-dom';
import React, { Suspense} from 'react';
import  BuildPage from './pages/BuildPage' ;
import  SpinPage from './pages/SlotPage' ;
import  CropPage from './pages/CropPage' ;
import  ShopPage from './pages/ShopPage' ;
import  FriendPage from './pages/FriendPage' ;
import  TonShopPage from './pages/TonShopPage' ;

const RedirectToBuild = () => {
  const { userId } = useParams();
  return <Navigate to={`/${userId}/build`} />;
};

function App() {
  return (
    <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/:userId" element={<RedirectToBuild />} />
            <Route path="/:userId/build" element={<BuildPage />} />
            <Route path="/:userId/spin" element={<SpinPage />} />
            <Route path="/:userId/crop" element={<CropPage />} />      
            <Route path="/:userId/friend" element={<FriendPage />} /> 
            <Route path="/:userId/tonshop" element={<TonShopPage />} /> 
            <Route path="/:userId/shop" element={<ShopPage />} />      
          </Routes>
        </Suspense>
    </Router>
  );
}
export default App;
