import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import './BoostIcon.css';

import {boost_backgroundImg, boost1, boostimg, boostMissle } from '../imgUrls'

import BoostCard from '../Shop/BoostCard';
import BoostTime from '../Navbar/BoostTime';

const BoostIcon = ({ total, now, user, func}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    console.log("Now, Total", now, "T", total);
    if(now > total || now < 0){
      func(5, user, 'boost_initial');
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleImageButtonClick = (image) => {
    console.log(`Clicked button for: ${image}`);
  };

  return (
    <>
      <Button onClick={handleClickOpen}>
        <img src={boostimg} alt="Open Dialog" style={{ width: '17vw', minWidth: '48px', maxWidth: '80px'}} />
      </Button>
      <Dialog
        BackdropProps={{
          style: {
            backgroundColor: 'black', // Sets the backdrop background color to black
            opacity: 0.6, // Optional: Adjust opacity as needed
          },
        }}
      PaperProps={{
        elevation : 0,
        style: {
          backgroundColor: 'transparent', // Set your desired background color here
          color: '#000000', // Optional: Set text color for contrast
          padding: '5px',
          width: '72vw',
          overflow: 'hidden',
          marginTop: '-6vh',
          height: '72vh',
        },
      }}
       open={open} onClose={handleClose} >
        <DialogContent sx={{
          backgroundImage: '../../assets/images/icons/Boost_3.webp', // Replace with your image patt
        }}
        style={{ 
          padding:'2vw',
            width: '72vw',maxWidth: '65vh', height:'60vh',
          textAlign:'center',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden'
        }} >
        <img src={boost1} alt="Open Dialog" style={{ width: '70vw', minWidth: '150px', maxWidth:'400px'}} />
        <div className="boost_gro">
          <img src={boost_backgroundImg} alt="Open Dialog" className="img_gro" />
        </div>
        <div className="boost_missile">
          <img src={boostMissle} alt="Open Dialog" className="img_missile" />
        </div>
        <BoostTime total={total} now={now > total ? 0 : now} />
            <Grid 
              container spacing={1}
              sx={{
                margin: '16vh 2vw 0vh 2vw',
                display: 'flex',
                justifyContent: 'center',
                width: '66vw',
                position: 'absolute',
                bottom: '3vh',
              }}
            >
                <Grid size={{ xs: 6, md: 6 }} >
                < BoostCard id={1} text='50% more' cost={0} func = {func} user = {user}  isValid={now > 1800 ? true : false}/>
                {/* < BoostCard id={1} text='50% more' cost={0} func = {func} user = {user}  isValid={true}/> */}
                </Grid>
                <Grid size={{xs: 6, md: 6}}>
                < BoostCard id={2} text={'80% more'} cost={7.89} func = {func} user = {user} isValid = {false} />
                </Grid>
                <Grid size={{xs: 6, md: 6 }}>
                < BoostCard id={3} text={'100% more'} cost={11.99} func = {func} user = {user} isValid = {false} />
                </Grid>
                <Grid size={{xs: 6, md: 6 }}>
                < BoostCard id={4} text={'120% more'} cost={129.99} func = {func} user = {user} isValid = {false} />
                </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default BoostIcon;